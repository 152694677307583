export function defDate() {
  const date = new Date();
  const mm =
    date.getMonth() + 1 >= 10
      ? date.getMonth + 1
      : '0' + String(date.getMonth() + 1);
  const dd = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
  const yyyy = date.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
}

export function defTime() {
  const date = new Date();

  return date.getHours() + ':' + date.getMinutes();
}

export const getHour = (str) => {
  let res = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] == ':') return parseInt(res);
    else res += str[i];
  }
  return 0;
};

export const getMinu = (str) => {
  let res = [];
  let str1 = str.split('').reverse();
  for (let i = 0; i < str1.length; i++) {
    if (str1[i] == ':') {
      let tmp = res.reverse();
      let phr = tmp.join('');
      return parseInt(phr);
    } else res.push(str1[i]);
  }
  return 0;
};

export function AreDateAndTimeValid(date, time) {
  const choosenDate = new Date(date);
  const todaysDate = new Date();

  if (choosenDate > todaysDate) return true;
  else {
    if (choosenDate.getFullYear() < todaysDate.getFullYear()) return false;
    else if (choosenDate.getMonth() < todaysDate.getMonth()) return false;
    else if (choosenDate.getDay() < todaysDate.getDay()) return false;
    else if (parseInt(time.split(':')[0]) <= todaysDate.getHours())
      return false;
    else return true;
  }
}
