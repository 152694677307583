import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Medical from './pages/medical';
import Colis from './pages/colis';
import Signin from './pages/signin';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/colis" element={<Colis />} />
          <Route path="/medical" element={<Medical />} />
          <Route path="/signin/:reseId" element={<Signin />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
