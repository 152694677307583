import Done from '../files/icons/done.svg';

export default function ProgressBar({ simulated, switchToReserve, reserved }) {
  // if not simulated
  if (!simulated)
    return (
      <div className="flex items-center justify-around w-[75%] mx-auto">
        <div className="w-[40px] h-[40px] rounded-[50%] bg-black"></div>
        <div className="flex-1 h-1 bg-black"></div>
        <div className="w-[30px] h-[30px] rounded-[50%] bg-black"></div>
      </div>
    );
  // if simulated
  // if second form is not shown
  else if (!switchToReserve)
    return (
      <div className="flex items-center justify-around w-[75%] mx-auto">
        <div className="w-[40px] h-[40px] rounded-[50%] bg-black flex justify-center items-center">
          <img src={Done} alt="done" />
        </div>
        <div className="flex-1 h-1 bg-black"></div>
        <div className="w-[30px] h-[30px] rounded-[50%] bg-black"></div>
      </div>
    );
  // if second form is shown
  else if (!reserved)
    return (
      <div className="flex items-center justify-around w-[75%] mx-auto">
        <div className="w-[40px] h-[40px] rounded-[50%] bg-black flex justify-center items-center">
          <img src={Done} alt="done" />
        </div>
        <div className="flex-1 h-1 bg-black"></div>
        <div className="w-[40px] h-[40px] rounded-[50%] bg-black flex"></div>
      </div>
    );
  // if reservation is done
  else
    return (
      <div className="flex items-center justify-around w-[75%] mx-auto">
        <div className="w-[40px] h-[40px] rounded-[50%] bg-black flex justify-center items-center">
          <img src={Done} alt="done" />
        </div>
        <div className="flex-1 h-1 bg-black"></div>
        <div className="w-[40px] h-[40px] rounded-[50%] bg-black flex justify-center items-center">
          <img src={Done} alt="done" />
        </div>
      </div>
    );
}
