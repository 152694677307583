import ProgressBar from './ProgressBar';
import ReservationInfo from './ReservationInfo';

export default function ReservationTop({
  simulated,
  switchToReserve,
  reserved,
  distance,
  price,
}) {
  return (
    <section className="w-[90%] sm:w-[60%] mx-auto mt-5">
      <ProgressBar
        simulated={simulated}
        switchToReserve={switchToReserve}
        reserved={reserved}
      />
      {simulated && !reserved && (
        <ReservationInfo distance={distance} price={price} />
      )}
    </section>
  );
}
