import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

// apiKey: 'AIzaSyBNlx0-BiBa2pdaCdQYzvJbJwzIjFTXAHg',
//   authDomain: 'tvtest-f3345.firebaseapp.com',
//   projectId: 'tvtest-f3345',
//   storageBucket: 'tvtest-f3345.appspot.com',
//   messagingSenderId: '461222282915',
//   appId: '1:461222282915:web:ed7e2d552de0784348e116',
//   measurementId: 'G-3JJ4BH9F7N',

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyCmALWoYzUNqVguT0JRULqyHwyoK-Nlzx0',
  authDomain: 'vtcars-e4f7d.firebaseapp.com',
  projectId: 'vtcars-e4f7d',
  storageBucket: 'vtcars-e4f7d.appspot.com',
  messagingSenderId: '525480304728',
  appId: '1:525480304728:web:35c18a4d690140132b2a2d',
  measurementId: 'G-RTPKEZJQ4L',
});

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
