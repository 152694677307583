import { useRef } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

export default function Form1({
  depart,
  setDepart,
  arrive,
  setArrive,
  date,
  setDate,
  time,
  setTime,
  simuler,
  canSimulate,
  goToForm2,
  handleSelect,
  handleSelectArrive,
}) {
  const inputRefDepart = useRef(null);
  const inputRefDate = useRef(null);
  const inputRefTime = useRef(null);
  const inputRefArrive = useRef(null);

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="w-[90%] sm:w-[60%] mx-auto mt-5"
    >
      <PlacesAutocomplete
        value={depart}
        onChange={setDepart}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="bg-black text-white w-[90%] my-2 rounded-1 py-1 px-2 sm:ml-10">
            <div>
              <p
                className="mb-0"
                onClick={() => inputRefDepart.current.focus()}
              >
                Adresse de prise en charge
              </p>
              <input
                ref={inputRefDepart}
                {...getInputProps({
                  placeholder:
                    "Adresse de départ . Veuillez saisir ici l'adresse",
                })}
                className="bg-black w-full rounded-1 placeholder-white outline-0 placeholder:italic"
              />
            </div>
            {getInputProps()['aria-expanded'] && (
              <div className="Rsec2__place1">
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion, id) => {
                  const style = {
                    backgroundColor: suggestion.active ? 'black' : '#fff',
                    padding: '0 8px',
                    color: suggestion.active ? 'white' : 'black',
                  };

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, { style })}
                      className="Rsec2__each"
                      key={id}
                    >
                      <i className="fa fa-map-marker"></i>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      <div className="text-white bg-black w-[90%] my-2 rounded-1 py-1 px-2 sm:ml-10">
        <p onClick={() => inputRefDate.current.focus()} className="mb-0">
          Prise en charge
        </p>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          ref={inputRefDate}
          className="bg-black flex justify-between w-full placeholder-white"
        />
      </div>
      <div className="text-white bg-black w-[90%] sm:w-[50%] my-2 rounded-1 py-1 px-2 sm:ml-10">
        <p onAuxClick={() => inputRefTime.current.focus()} className="mb-0">
          Heure
        </p>
        <input
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          ref={inputRefTime}
          className="bg-black flex justify-between w-full"
        />
      </div>
      <PlacesAutocomplete
        value={arrive}
        onChange={setArrive}
        onSelect={handleSelectArrive}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="bg-black text-white w-[90%] my-2 rounded-1 py-1 px-2 sm:ml-10">
            <div>
              <p
                className="mb-0"
                onClick={() => inputRefArrive.current.focus()}
              >
                Destination
              </p>
              <input
                ref={inputRefArrive}
                {...getInputProps({
                  placeholder: 'Destination. Calculer le prix de la commande',
                })}
                className="bg-black w-full rounded-1 placeholder-white outline-0 placeholder:italic"
              />
            </div>
            {getInputProps()['aria-expanded'] && (
              <div className="Rsec2__place1">
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion, id) => {
                  const style = {
                    backgroundColor: suggestion.active ? 'black' : '#fff',
                    padding: '0 8px',
                    color: suggestion.active ? 'white' : 'black',
                  };

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, { style })}
                      className="Rsec2__each"
                      key={id}
                    >
                      <i className="fa fa-map-marker"></i>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
      <div className="w-[90%] my-4 rounded-1 sm:ml-10 sm:flex sm:justify-around text-center">
        <button
          className="rounded-5 bg-black max-w-[250px] min-w-[200px] mr-2 mb-2 h-[45px] text-white text-[18px] font-bold"
          onClick={simuler}
          disabled={!canSimulate}
        >
          Simulation
        </button>
        <button
          className="rounded-5 bg-black max-w-[250px] min-w-[200px] mr-2 mb-2 h-[45px] text-white text-[18px] font-bold"
          onClick={goToForm2}
          disabled={!canSimulate}
        >
          Réservation
        </button>
      </div>
      {/* <div className="w-[90%]  sm:ml-10">
        {!impossibleRes && (
          <p>Choisissez une date et une heure valid (en future)</p>
        )}
      </div> */}
    </form>
  );
}
