import Header from '../../components/Header';
import Email from '../../files/icons/blackEmail.svg';
import Phone from '../../files/icons/blackPhone.svg';
import Colis from '../../files/colis.jpg';
import Map from '../../files/map.jpg';

export default function Index() {
  return (
    <div>
      <Header />
      <div className="sm:mt-[200px] w-[85%] mx-auto text-center">
        <h1>Un chauffeur pour l’acheminement de vos colis, toutes distances</h1>
        <p>
          Que vous soyez un particulier ou un professionnel, notre entreprise de
          transports vous propose ses services pour effectuer le transport de
          vos colis.
          <br />
          Besoin d’un <b>transporteur express</b>, nous assurons l’acheminement
          de tous types de colis urgent, encombrant, fragile et lettre suivie…
          <br />
          <b>
            Faites-nous part de vos besoins nous nous adaptons à toutes vos
            exigences.
          </b>
        </p>
      </div>
      <div className="sm:flex px-5 justify-around mt-10">
        <div className="">
          <div className="flex-1">
            <h1 className="pb-8 text-[40px] font-extraboldx] alignment">
              Contactez-nous
            </h1>
            <div className="mb-2 sm:pl-0 pl-4">
              <a
                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                target="_blank"
                className="text-20 font-extrabold montserrat flex items-center text-black no-underline"
              >
                <img
                  src={Email}
                  alt="email icon"
                  className="pr-2 w-10 text-black"
                />
                <p className="mb-0">{process.env.REACT_APP_EMAIL}</p>
              </a>
            </div>
            <div className="mb-2 sm:pl-0 pl-4">
              <a
                href={`tel:${process.env.REACT_APP_URL_PHONE}`}
                target="_blank"
                className="text-20 font-extrabold montserrat flex no-underline items-center text-black"
              >
                <img src={Phone} alt="phone icon" className="pr-2 w-10" />

                <p className="mb-0">{process.env.REACT_APP_SHOW_PHONE}</p>
              </a>
            </div>
          </div>
        </div>
        <img className="w-[250px] object-contain" src={Colis} alt="CPAM logo" />
        <img className="w-[300px]" src={Map} alt="CPAM logo" />
      </div>
    </div>
  );
}
