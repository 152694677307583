const data = [
  'Entrez l’adresse de départ, l’adresse d’arrivée, la date et l’heure de prise en charge',
  'Estimez le prix de votre course et entrer vos informations',
  'Voyagez tranquillement',
];

export default function Cards() {
  return (
    <div className="cards block sm:flex justify-around mb-2">
      {data?.map((ele, idx) => {
        return (
          <div
            key={idx}
            className="flex-1 max-w-[350px] min-w-[225px] text-center py-3 px-2 mx-auto my-1"
          >
            <div className="w-[45px] h-[45px] bg-black text-white rounded-[50%] mx-auto flex justify-center items-center text-[19px] font-extrabold">
              {idx + 1}
            </div>
            <h4 className="text-[16px] text-normal">{ele}</h4>
          </div>
        );
      })}
    </div>
  );
}
