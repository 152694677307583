import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="mt-2">
      <div className="pc hidden sm:block">
        <Navbar fixed="top" className="bg-white">
          <div className="top text-right px-5 w-full">
            <Link to="/" className="text-inherit no-underline hover:text-seco">
              <h1 className="montserrat text-[40px] font-extrabold leading-[25px]">
                VTCARS
              </h1>
            </Link>
            <h5 className="text-[25px] font-thin mb-3">
              <a
                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                className="text-black no-underline"
                target="_blank"
              >
                {process.env.REACT_APP_EMAIL}
              </a>
            </h5>
            <h6 className="text-[25px] font-bold leading-[20px]">
              <a
                href={`tel:${process.env.REACT_APP_URL_PHONE}`}
                className="text-black border-x-2 border-y-2 p-1 rounded-5 border-black no-underline"
                target="_blank"
              >
                {process.env.REACT_APP_SHOW_PHONE}
              </a>
            </h6>
          </div>
          <div className="buttom w-full bg-black mt-2 text-white montserrat text-[16px] font-extrabold">
            <ul className="flex w-fit mx-auto mb-0">
              <Link
                to="/"
                className="text-inherit no-underline hover:text-seco"
              >
                <li className="pr-5 py-3">Vtcars</li>
              </Link>
              <Link
                to="/medical"
                className="text-inherit no-underline hover:text-seco"
              >
                <li className="px-5 border-x-2 border-white py-3">
                  Transport médical
                </li>
              </Link>
              <Link
                to="/colis"
                className="text-inherit no-underline hover:text-seco"
              >
                <li className="pl-5 py-3">Transport de colis</li>
              </Link>
            </ul>
          </div>
        </Navbar>
      </div>
      <div className="phone block sm:hidden mt-2 h-[80px]">
        <Navbar fixed="top" className="bg-white">
          <div>
            <h1 className="montserrat text-[25px] font-extrabold leading-[15px]">
              VTCARS
            </h1>
            <h5 className="text-[16px] font-thin">
              <a
                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                className="text-black no-underline"
                target="_blank"
              >
                {process.env.REACT_APP_EMAIL}
              </a>
            </h5>
            <h6 className="text-[16px] font-bold leading-[17px]">
              <a
                href={`tel:${process.env.REACT_APP_URL_PHONE}`}
                className="text-black no-underline"
                target="_blank"
              >
                {process.env.REACT_APP_SHOW_PHONE}
              </a>
            </h6>
          </div>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="ml-auto bg-black montserrat font-meduim text-white"
              navbar
            >
              <Link to="/" className="text-inherit no-underline pb-2">
                <li>Vtcars</li>
              </Link>
              <Link to="/medical" className="text-inherit no-underline pb-2">
                <li>Transport médical</li>
              </Link>
              <Link to="/colis" className="text-inherit no-underline pb-2">
                <li>Transport de colis</li>
              </Link>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </nav>
  );
}
