import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Reservation from '../../components/Res';
import Hero from '../../files/hero.png';
import Cards from './Cards';

export default function Index() {
  return (
    <div>
      <Header />
      <div className="hero">
        <img
          src={Hero}
          alt="hero"
          className="sm:mt-[175px] mx-auto sm:w-[90%]"
        />
      </div>
      <Reservation />
      <h1 className="text-center text-[40px] font-extrabold">
        Pour chacun son moyen de transport
      </h1>
      <h5 className="text-center text-[17px] text-normal">
        Commander un taxi sur le site VTCARS est extrêmement simple et sécurisé.
      </h5>
      <Cards />
      <Footer />
    </div>
  );
}
