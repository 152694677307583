import Header from '../../components/Header';
import Email from '../../files/icons/blackEmail.svg';
import Phone from '../../files/icons/blackPhone.svg';
import Location from '../../files/icons/blackLocation.svg';
import CPAM from '../../files/conv.jpg';

export default function Index() {
  return (
    <div>
      <Header />
      <div className="sm:mt-[200px] w-[85%] mx-auto text-center">
        <h1>
          La société VTCARS réalise vos transports sanitaires en taxi
          conventionné
        </h1>
        <p>
          Vous disposez d’une prescription médicale de transport en position
          assise afin de vous rendre dans un établissement de santé ou pour
          retourner à votre domicile.
        </p>
        <p>
          La société <b>VTCARS</b> vous prend en charge à bord d’un{' '}
          <b>
            taxi conventionné agréer par la Caisse Primaire d’Assurance Maladie
          </b>
          (CPAM) en toute sécurité afin de vous conduire là où vous avez besoin,
          que ce soit pour des soins, des consultations ou encore des trajets
          d’entrée et sortie d’hospitalisation.
        </p>
      </div>
      <div className="sm:flex px-5 justify-around mt-10">
        <div className="flex-1">
          <div className="flex-1">
            <h1 className="pb-8 text-[40px] font-extraboldx] alignment">
              Contactez-nous
            </h1>
            <div className="mb-2 sm:pl-0 pl-4">
              <a
                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                target="_blank"
                className="text-20 font-extrabold montserrat no-underline flex items-center text-black"
              >
                <img
                  src={Email}
                  alt="email icon"
                  className="pr-2 w-10 text-black"
                />
                <p className="mb-0">{process.env.REACT_APP_EMAIL}</p>
              </a>
            </div>
            <div className="mb-2 sm:pl-0 pl-4">
              <a
                href={`tel:${process.env.REACT_APP_URL_PHONE}`}
                target="_blank"
                className="text-20 font-extrabold montserrat flex no-underline items-center text-black"
              >
                <img src={Phone} alt="phone icon" className="pr-2 w-10" />

                <p className="mb-0">{process.env.REACT_APP_SHOW_PHONE}</p>
              </a>
            </div>
            <div className="text-20 font-extrabold montserrat flex align-middle mb-2 items-center sm:pl-0 pl-4">
              <img src={Location} alt="location icon" className="pr-2 w-10" />
              <p className="mb-0">Strasbourg</p>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <img src={CPAM} alt="CPAM logo" className="w-full" />
        </div>
      </div>
    </div>
  );
}
