import Email from "../files/icons/email.svg";
import Phone from "../files/icons/phone.svg";
import Location from "../files/icons/location.svg";
import Twitter from "../files/icons/Twitter.svg";
import Instagram from "../files/icons/Instagram.svg";
import Google from "../files/icons/Google.svg";

export default function Footer() {
  return (
    <footer className=" bg-black text-white pt-7 p-4 mt-10">
      <section className="sm:flex">
        <div className="w-full flex-1">
          <h1 className="pb-8 text-[40px] font-extrabold">VTCARS</h1>
          <p className="text-20 font-extrabold montserrat">
            Autorisations de stationnement TAXI C.U.S
          </p>
          <p className="text-20 font-extrabold montserrat">
            Eurométropole de strasbourg
          </p>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <p className="text-20 font-extrabold montserrat sm:mx-2">
            Taxis disponibles sur toute l'Alsace
          </p>
        </div>
        <div className="flex-1 sm:mr-10">
          <h1 className="pb-8 text-[40px] font-extrabold sm:text-right">
            Contact
          </h1>
          <div className="mb-2 sm:pl-0 flex sm:justify-end">
            <a
              href={`mailto:${process.env.REACT_APP_EMAIL}`}
              target="_blank"
              className="text-20 font-extrabold montserrat w-fit flex text-white items-center no-underline"
            >
              <img
                src={Email}
                alt="email icon"
                className="p-2 w-10 sm:order-2"
              />
              <p className="mb-0">{process.env.REACT_APP_EMAIL}</p>
            </a>
          </div>
          <div className="mb-2 sm:pl-0 flex sm:justify-end">
            <a
              href={`tel:${process.env.REACT_APP_URL_PHONE}`}
              target="_blank"
              className="text-20 font-extrabold montserrat flex text-white no-underline items-center"
            >
              <img
                src={Phone}
                alt="phone icon"
                className="p-2 w-10 sm:order-2"
              />
              <p className="mb-0">{process.env.REACT_APP_SHOW_PHONE}</p>
            </a>
          </div>
          <div className="mb-2 sm:pl-0 flex sm:justify-end">
            <img
              src={Location}
              alt="location icon"
              className="p-2 w-10 sm:order-2"
            />
            <p className="mb-0 mt-2 text-20 font-extrabold montserrat">
              Strasbourg
            </p>
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="sm:flex justify-between items-center sm:px-5">
          <div className="flex-1 w-[75%] mx-auto mb-4 sm:mb-0 flex justify-around">
            <a
              href="https://twitter.com/VTCARS67?t=5faLT0NpZv7wTwz9brCAYQ&s=08"
              target="_blank"
              className="text-white no-underline text-center text-[17px] sm:text-[20px] font-bold montserrat flex flex-col"
            >
              <img
                src={Twitter}
                alt="twitter icon"
                className="mx-auto w-[30px]"
              />
              Twitter
            </a>
            <a
              href="https://www.instagram.com/vtcars67/"
              target="_blank"
              className="text-white no-underline text-center text-[17px] sm:text-[20px] font-bold montserrat flex flex-col"
            >
              <img
                src={Instagram}
                alt="instagram icon"
                className="mx-auto w-[30px]"
              />
              Instagram
            </a>
            <a
              href="https://g.page/vtcars?share"
              target="_blank"
              className="text-white no-underline text-center text-[17px] sm:text-[20px] font-bold montserrat flex flex-col"
            >
              <img
                src={Google}
                alt="google icon"
                className="mx-auto w-[30px]"
              />
              Google
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
}
