import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import ReservationDetails from '../../components/ReservationDetails';
import { auth, db } from '../../firebase';
import axios from 'axios';

export default function Index() {
  const reseId = window.location.href.split('signin/')[1];

  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [logginError, setLogginError] = useState('');
  const [reservationError, setReservationError] = useState('');
  const [reservation, setReservation] = useState({});
  const [confirmationError, setConfirmationError] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    async function getRes() {
      setLoading(true);
      setLoadingMessage('Obtenir les informations sur la réservation');
      try {
        const res = await db.collection('reservations').doc(reseId).get();
        const newReservation = res.data().data;
        if (res?.data()?.Reservationconfirmed)
          newReservation.Reservationconfirmed = true;
        setReservation(newReservation);
      } catch (e) {
        console.error(e);
        setReservationError("URL erronée, la réservation n'existe pas");
      } finally {
        setLoading(false);
        setLoadingMessage('');
      }
    }

    if (loggedIn) {
      getRes();
    }
  }, [loggedIn]);

  const login = async () => {
    setLoading(true);
    setLoadingMessage('En cours de connection');

    try {
      const authenticated = await auth.signInWithEmailAndPassword(
        email,
        password
      );
      if (authenticated.user) setLoggedIn(true);
    } catch (e) {
      console.error(e);
      setLogginError("fausses informations d'identification");
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };

  const confirmation = async (conf) => {
    const data = { ...reservation, conf };
    setLoading(true);
    setLoadingMessage(conf ? 'Confirmation' : 'Annulation');

    try {
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }confirm?res=${JSON.stringify(data)}`;
      await axios.get(url);
      await db
        .collection('reservations')
        .doc(reseId)
        .update({ Reservationconfirmed: true });
      setConfirmed(true);
      const newReservation = Object.assign({}, reservation);
      newReservation.confirmed = true;
      setReservation(newReservation);
    } catch (e) {
      setConfirmed(false);
      setConfirmationError(
        "quelque chose s'est mal passé, réessayez plus tard"
      );
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <div className="relative min-h-[400px]">
      {loading && <Loading string={loadingMessage} />}
      {!loggedIn ? (
        <div className="w-full bg-seco max-w-[400px] mx-auto mt-10 p-5">
          <div className="mb-5">
            <p className="mb-0">Email</p>
            <input
              className="p-1 w-full"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Tapez votre email"
            />
          </div>
          <div className="mb-5">
            <p className="mb-0">Password</p>
            <input
              className="p-1 w-full"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Tapez votre mot de passe"
            />
          </div>
          <div>
            <button
              className="bg-prim w-[200px] h-[45px] text-white text-[20px] font-bold"
              disabled={email === '' || password === ''}
              onClick={login}
            >
              Login
            </button>
          </div>
          <div>
            {logginError !== '' && <p className="text-prim">{logginError}</p>}
          </div>
        </div>
      ) : reservationError === '' ? (
        <ReservationDetails
          reservation={reservation}
          confirmation={confirmation}
          confirmationError={confirmationError}
          confirmed={confirmed}
        />
      ) : (
        <div>
          <p className="text-prim">{reservationError}</p>
        </div>
      )}
    </div>
  );
}
