import { useEffect, useState } from 'react';
import {
  AreDateAndTimeValid,
  defDate,
  defTime,
  getHour,
  getMinu,
} from '../helper';
import Form1 from './Form1';
import Loading from './Loading';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios';
import ReservationTop from './ReservationTop';
import Form2 from './Form2';
import { db } from '../firebase.js';

const joursFeries = [
  '2023-01-01',
  '2023-04-09',
  '2023-04-10',
  '2023-05-01',
  '2023-05-08',
  '2023-05-18',
  '2023-05-29',
  '2023-07-14',
  '2023-08-15',
  '2023-11-01',
  '2023-11-11',
  '2023-12-25',
];

export default function Reservation() {
  // global state
  const [switchToReserve, setSwitchToReserve] = useState(false);
  const [impossibleRes, setImpossibleRes] = useState(false);
  const [simulated, setSimulated] = useState(false);
  const [reserved, setReserved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Chargement');
  const [error, setError] = useState('');

  // second form state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [adresse, setAdresse] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('directly');
  const [message, setMessage] = useState('');

  // first form state
  const [depart, setDepart] = useState('');
  const [arrive, setArrive] = useState('');
  const [departPlaceId, setDepartPlaceId] = useState('');
  const [arrivePlaceId, setArrivePlaceId] = useState('');
  const [date, setDate] = useState(defDate());
  const [time, setTime] = useState(defTime());
  const [price, setPrice] = useState(0);
  const [distance, setDistance] = useState(0);
  const [departCoordinates, setDepartCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [arriveCoordinates, setArriveCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setDepartPlaceId(results[0].place_id);
    const latLng = await getLatLng(results[0]);
    setDepart(value);
    setDepartCoordinates(latLng);
  };
  const handleSelectArrive = async (value) => {
    const results = await geocodeByAddress(value);
    setArrivePlaceId(results[0].place_id);
    const latLng = await getLatLng(results[0]);
    setArrive(value);
    setArriveCoordinates(latLng);
  };

  const getPrice = (dis) => {
    if (
      new Date(date).getDay() === 0 ||
      joursFeries.some((jour) => jour === date)
    ) {
      return ((dis.value / 1000) * 2.48).toFixed(2);
    } else if (getHour(time) === 18) {
      if (getMinu(time) >= 55) return ((dis.value / 1000) * 2.48).toFixed(2);
      else return ((dis.value / 1000) * 1.74).toFixed(2);
    } else if (getHour(time) >= 19 || getHour(time) < 7)
      return ((dis.value / 1000) * 2.48).toFixed(2);
    else return ((dis.value / 1000) * 1.74).toFixed(2);
  };

  useEffect(() => {
    setImpossibleRes(AreDateAndTimeValid(date, time));
  }, [date, time]);

  const simuler = async () => {
    setLoading(true);
    setLoadingMessage('Calculation de prix');
    const url = `${process.env.REACT_APP_BACKEND_URL}distance/?place1=${departPlaceId}&place2=${arrivePlaceId}`;

    try {
      const res = await axios.get(url);
      setDistance(res.data);
      setPrice(parseFloat(getPrice(res.data)) + 6.5);
      setSimulated(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setLoadingMessage('');
      document.getElementById('reservationSection').scrollIntoView();
    }
  };

  const goToForm2 = async () => {
    if (!simulated) {
      await simuler();
    }
    setSwitchToReserve(true);
    document.getElementById('reservationSection').scrollIntoView();
  };

  const Reserve = async () => {
    setLoading(true);
    setLoadingMessage('En course de Réservation');
    const data = {
      firstName,
      lastName,
      depart,
      arrive,
      date,
      time,
      number,
      email,
      adresse,
      paymentMethod,
      Reservationconfirmed: false,
      price,
      paid: false,
      message: message.replace(/[\r\n]/gm, '. '),
    };
    try {
      const result = await db.collection('reservations').add({
        data,
      });
      const reservationId = result.id;
      // send the email
      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }reservation?data=${JSON.stringify(data)}&reseId=${reservationId}`;
      await axios.get(url);
      setReserved(true);
    } catch (e) {
      console.error(e);
      setError(
        e.message || "quelque chose s'est mal passé, réessayez plus tard"
      );
      setReserved(false);
    } finally {
      setLoading(false);
      setLoadingMessage('');
      document.getElementById('reservationSection').scrollIntoView();
    }
  };

  const canSimulate = depart !== '' && arrive !== '' && impossibleRes;

  const canReserve =
    simulated !== false &&
    switchToReserve !== false &&
    firstName !== '' &&
    lastName !== '' &&
    number !== '' &&
    email !== '' &&
    adresse !== '';
  return (
    <div className="relative min-h-[400px] my-10" id="reservationSection">
      <h1 className="text-center text-[40px] font-extrabold">
        Commander votre taxi
      </h1>
      {loading === true && <Loading string={loadingMessage} />}

      <ReservationTop
        simulated={simulated}
        switchToReserve={switchToReserve}
        reserved={reserved}
        distance={distance?.value / 1000}
        price={price}
      />
      {/* {simulated && switchToReserve ? ( */}
      {!simulated || !switchToReserve ? (
        <Form1
          depart={depart}
          setDepart={setDepart}
          handleSelect={handleSelect}
          handleSelectArrive={handleSelectArrive}
          arrive={arrive}
          setArrive={setArrive}
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
          simuler={simuler}
          canSimulate={canSimulate}
          goToForm2={goToForm2}
          impossibleRes={impossibleRes}
        />
      ) : !reserved ? (
        <Form2
          firstName={firstName}
          lastName={lastName}
          number={number}
          email={email}
          adresse={adresse}
          paymentMethod={paymentMethod}
          message={message}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setNumber={setNumber}
          setEmail={setEmail}
          setAdresse={setAdresse}
          setPaymentMethod={setPaymentMethod}
          setMessage={setMessage}
          canReserve={canReserve}
          Reserve={Reserve}
          error={error}
        />
      ) : (
        <div>
          <h3 className="text-center montserrat font-extrabold">
            Félicitations, nous vous remercions et confirmons que votre
            réservation a bien été prise en compte.
          </h3>
          <h6 className="text-center">
            Son traitement sera effectif dans les meilleurs délais, attendez de
            recevoir le mail de confirmation.
          </h6>
        </div>
      )}
      {reserved && (
        <div className="text-center">
          <span className="text-[14px] montserrat">
            Merci de vérifier votre dossier spam / courrier indésirable au cas
            où vous n'auriez pas reçu d'e-mails de confirmation
          </span>
        </div>
      )}
    </div>
  );
}
