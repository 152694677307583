export default function ReservationInfo({ distance, price }) {
  return (
    <div className="w-[75%] mx-auto sm:flex justify-around my-7">
      <div className="text-center">
        <h4>Distance</h4>
        <p className="font-bold">{distance} Km</p>
      </div>
      <div className="text-center">
        <h4>Prix</h4>
        <p className="font-bold">{price} €</p>
      </div>
    </div>
  );
}
