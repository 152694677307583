import { useRef } from 'react';

export default function Form2({
  firstName,
  lastName,
  number,
  email,
  adresse,
  paymentMethod,
  message,
  setFirstName,
  setLastName,
  setNumber,
  setEmail,
  setAdresse,
  setPaymentMethod,
  setMessage,
  canReserve,
  Reserve,
  error,
}) {
  const name_ref = useRef(null);
  const prename_ref = useRef(null);
  const tel_ref = useRef(null);
  const email_ref = useRef(null);
  const live_ref = useRef(null);
  const pay_ref = useRef(null);
  const msg_ref = useRef(null);

  return (
    <form
      className="w-[90%] sm:w-[60%] mx-auto mt-5"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="input-field-wraper">
        <p onClick={() => name_ref.current.focus()}>
          Votre Nom <small>*</small>
        </p>
        <input
          ref={name_ref}
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Tapez votre nom"
        />
      </div>
      <div className="input-field-wraper">
        <p onClick={() => prename_ref.current.focus()}>
          Votre Prenom <small>*</small>
        </p>
        <input
          ref={prename_ref}
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Tapez votre Prenom"
        />
      </div>
      <div className="input-field-wraper">
        <p onClick={() => tel_ref.current.focus()}>
          Votre Numero de telephone <small>*</small>
        </p>
        <input
          ref={tel_ref}
          type="tel"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          placeholder="Tapez votre numero"
        />
      </div>
      <div className="input-field-wraper">
        <p onClick={() => email_ref.current.focus()}>
          Votre Email <small>*</small>
        </p>
        <input
          ref={email_ref}
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Tapez votre email"
        />
      </div>
      <div className="input-field-wraper">
        <p onClick={() => live_ref.current.focus()}>
          Votre Adresse <small>*</small>
        </p>
        <input
          ref={live_ref}
          type="text"
          value={adresse}
          onChange={(e) => setAdresse(e.target.value)}
          placeholder="Tapez votre adresse"
        />
      </div>
      <div className="input-field-wraper">
        <p onClick={() => pay_ref.current.focus()}>
          Moyen de paiement <small>*</small>
        </p>
        <select
          ref={pay_ref}
          name="moyen"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <option value="card">
            Payer directement au chauffeur par Carte bancaire
          </option>
          <option value="directly">
            Payer directement au chauffeur en espèces
          </option>
        </select>
      </div>
      <div className="input-field-wraper">
        <p onClick={() => msg_ref.current.focus()}>Ajoutez un message</p>
        <textarea
          ref={msg_ref}
          value={message}
          rows={3}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Tapez votre message"
        ></textarea>
      </div>
      <button
        className="bg-black max-w-[250px] min-w-[200px] block mx-auto mb-2 h-[45px] text-white text-[18px] font-bold"
        disabled={!canReserve}
        onClick={Reserve}
      >
        Réservation
      </button>
      <div className="w-[90%]  sm:ml-10">
        <span className="text-center text-[14px] montserrat">
          Merci de vérifier votre dossier spam / courrier indésirable au cas où
          vous n'auriez pas reçu d'e-mails de confirmation
        </span>
        {error !== '' && <p className="text-prim">{error}</p>}
      </div>
    </form>
  );
}
