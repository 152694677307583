export default function ReservationDetails({
  reservation,
  confirmation,
  confirmationError,
  confirmed,
}) {
  const {
    firstName,
    lastName,
    date,
    time,
    depart,
    arrive,
    email,
    number,
    price,
    paymentMethod,
    adresse,
    message,
    Reservationconfirmed,
  } = reservation;

  console.log(reservation);

  return (
    <div className="w-full bg-seco max-w-[500px] mx-auto mt-2 p-5">
      <h2>Client</h2>
      <div className="element-dts">
        <p className="element-dts-type">Nom</p>
        <p>{firstName}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Prenom</p>
        <p>{lastName}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Email</p>
        <p>{email}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Numéro</p>
        <p>{number}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Addresse</p>
        <p>{adresse}</p>
      </div>
      <h2>Réservation</h2>
      <div className="element-dts">
        <p className="element-dts-type">Date</p>
        <p>{date}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Heure</p>
        <p>{time}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Depart</p>
        <p>{depart}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Arrive</p>
        <p>{arrive}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Prix</p>
        <p>{price} €</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Message</p>
        <p>{message}</p>
      </div>
      <div className="element-dts">
        <p className="element-dts-type">Payement</p>
        <p>{paymentMethod === 'card' ? 'Par carte' : 'En espèces'}</p>
      </div>
      {Reservationconfirmed === false ? (
        <div className="sm:flex justify-around">
          <button
            onClick={() => {
              confirmation(true);
            }}
            className="mr-2 bg-prim text-white text-[19px] font-bold mb-2 w-[200px] h-[45px]"
          >
            Confirmer
          </button>
          <button
            onClick={() => {
              confirmation(false);
            }}
            className="bg-prim text-white text-[19px] font-bold mb-2 w-[200px] h-[45px]"
          >
            Annuler
          </button>
        </div>
      ) : (
        <div className="sm:flex justify-around">
          <p className="=montserrat">Réservation deja traité</p>
        </div>
      )}
      {confirmed && (
        <div className="text-center">
          <p className="=montserrat">C'est confirmé</p>
        </div>
      )}
      {confirmationError !== '' && (
        <div className="text-center">
          <p className="=montserrat">{confirmationError}</p>
        </div>
      )}
    </div>
  );
}
